import { onError } from '@apollo/client/link/error';

export default onError(({ graphQLErrors, networkError, operation }) => {
  console.error(`GraphQL operation "${operation.operationName}" failed`, operation.variables);

  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      console.error(`[GraphQL error]: Message: ${error.message}, Path: ${error.path}`);
    }
  }

  if (networkError) {
    if ('statusCode' in networkError && networkError.statusCode === 401) {
      if (typeof window !== 'undefined') {
        window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname + '?' + window.location.search)}`;
      }
    }

    console.error(`[Network error]: ${networkError.message}`);
  }
});
