import { RetryLink } from '@apollo/client/link/retry';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      console.warn(`Retrying ${operation.operationName} with statusCode ${error.statusCode}`, error);

      return !!error && error.statusCode !== 401;
    },
  },
});

export default retryLink;
