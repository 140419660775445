'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: `https://${process.env.NEXT_PUBLIC_POSTHOG_REGION}.posthog.com`,
    person_profiles: 'identified_only',
  });
}

export function AnalyticsProvider({
  children,
}: React.PropsWithChildren) {
  return (
    <PostHogProvider client={posthog}>
      {children}
    </PostHogProvider>
  );
}
