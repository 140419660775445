'use client';

import { ApolloLink, HttpLink, split } from '@apollo/client';
import { ApolloNextAppProvider, ApolloClient, InMemoryCache } from '@apollo/experimental-nextjs-app-support';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import onError from './links/onError';
import retryLink from './links/retry';

function makeClient(accessToken?: string | null) {
  const options = {
    uri: typeof window === 'undefined' ? process.env.GRAPHQL_URL : '/api/graphql',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const httpLink = split(
    operation => operation.operationName === 'GetProductListingPriceQuery',
    new BatchHttpLink(options),
    new HttpLink(options),
  );

  return () => {
    return new ApolloClient({
      connectToDevTools: process.env.VERCEL_ENV !== 'production',
      cache: new InMemoryCache({
        typePolicies: {
          /**
           * Disable merging of warnings for BasketItem to avoid the error "ERROR TypeError: Cannot convert object to primitive value"
           * when the basket item is updated on the basket page.
           *
           * @see https://github.com/apollographql/apollo-client/issues/11544#issuecomment-1918756931
           * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
           */
          BasketItem: {
            fields: {
              warnings: {
                merge: false,
              },
            },
          },
        },
      }),
      link: ApolloLink.from([
        retryLink,
        onError,
        httpLink,
      ]),
    });
  };
}

export function ApolloWrapper({
  accessToken,
  children,
}: {
  accessToken?: string | null;
  children: React.ReactNode;
}) {
  return (
    <ApolloNextAppProvider makeClient={makeClient(accessToken)}>
      {children}
    </ApolloNextAppProvider>
  );
}
