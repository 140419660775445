import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --theme-primary-color: ${props => props.theme.palette.primary.main};
    --theme-primary-color-text: ${props => props.theme.palette.primary.contrastText};
    --theme-secondary-color: ${props => props.theme.palette.secondary.main};
    --theme-secondary-color-text: ${props => props.theme.palette.secondary.contrastText};
  }

  html,
  body {
    font-family: ${props => props.theme.fontFamily?.value ?? 'default'};
    background-color: ${props => props.theme.palette?.background.default ?? 'inherit'};
  }

  html,
  body,
  form,
  ul,
  h1,
  h2,
  h3,
  h4 {
    padding: 0;
    margin: 0;
    color: ${props => props.theme.palette?.text.primary ?? 'inherit'};
  }

  h1,
  h2,
  h3,
  h4 {
    p {
      margin: 0;
    }
  }

  ul {
    list-style-position: inside;
  }

  * {
    box-sizing: border-box;
  }

  .aa-Panel {
    z-index: 99999;
  }

  .image-gallery-slides {
    height: unset !important;
  }

  .image-gallery-slide, .image-gallery-image {
    /* max-height: unset;
    height: 100%; */
  }

  .no-width {
    width: unset;
  }

  .calendar-days {
    gap: 2px 0;
  }

  .calendar-day-number {

  }

  .calendar-selected {
    border-radius: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .calendar-day-hover {

  }

  .calendar-day-available {
    background-color: ${props => props.theme.palette?.success.light ?? 'inherit'};
    border-radius: 0;
    border: 1px solid ${props => props.theme.palette?.success.main ?? 'inherit'};
  }

  .calendar-day-disabled {
    background-color: #eee;
    border-radius: 0;
    border: 0;
  }

  .calendar-day-passive {
    background-color: white;
    border: 0;
  }

  @media (max-width: 768px) {
    .hideOnMobile {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .hideOnDesktop {
      display: none;
    }
  }
`;

export default GlobalStyle;
